.tutoring-areas {
  margin: 2rem 0;
}

.tutoring-areas section {
  margin-bottom: 2rem;
}

.tutoring-areas h3 {
  margin-bottom: 1rem;
}

.tutoring-areas ul {
  list-style-type: none;
  padding-left: 1rem;
}

.tutoring-areas li {
  margin-bottom: 0.5rem;
}

.contact-info {
  margin-top: 3rem;
}
